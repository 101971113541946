import { LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import "./_email-form";
import { polyfillDeclarativeShadowDOM } from "../../utils/dom";
import { styles } from "./global-footer.styles";

@customElement("bp-global-footer")
export class GlobalFooter extends LitElement {
  static styles = styles;

  constructor() {
    super();
    polyfillDeclarativeShadowDOM(this);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "bp-global-footer": GlobalFooter;
  }
}
