import { css } from "lit";

export const styles = css`
  :host {
    --global-footer-max-width: 1360px;
    --logo-height: var(--size-6);

    display: block;
    background-color: var(--color-black);
  }

  footer {
    color: var(--color-white);
    max-width: var(--global-footer-max-width);
    padding: var(--size-4);
    margin-inline: auto;
  }

  footer a {
    color: currentColor;
    text-decoration: none;
  }

  .callout {
    display: grid;
    grid-template-rows: auto;
    gap: var(--size-4);
    margin-block-start: var(--size-4);
    margin-block-end: var(--size-4);
  }

  .callout-text {
    font-size: var(--font-size-5xl);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-tight);
    background: linear-gradient(
        91.71deg,
        rgba(105, 180, 226, 0.4) 5.53%,
        rgba(215, 145, 216, 0.4) 93.91%
      ),
      #ffffff;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .callout-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .callout-button > a {
    display: inline-flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: var(--size-2);
    height: var(--size-6);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-none);
    background-color: var(--color-brand);
    color: var(--color-black);
    border-radius: var(--radius-lg);
    text-decoration: none !important;
    white-space: nowrap;
  }

  .callout + .footer {
    position: relative;
    margin-block-start: var(--size-6);
    padding-block-start: var(--size-6);
  }

  .callout + .footer::before {
    content: "";
    position: absolute;
    height: 1px;
    inset-inline: 0;
    inset-block-start: 0;
    background-color: var(--color-neutral-60);
  }

  .footer {
    display: grid;
    grid-template-rows: auto;
    align-items: start;
    gap: var(--size-4);
    margin-block-end: var(--size-8);
  }

  .footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .site-links a {
    display: block;
    padding-block: var(--size-1);
  }

  .icon-link-label {
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing-wide);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-sm);
  }

  .icon-links > ul {
    display: flex;
    flex-wrap: wrap;
    gap: var(--size-2);
  }

  .icon-link-label + ul {
    margin-block-start: var(--size-2);
  }

  ul + .icon-link-label {
    margin-block-start: var(--size-4);
  }

  .icon-links a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: var(--radius-full);
    height: var(--size-5);
    width: var(--size-5);
    border: 1px solid currentColor;
  }

  .copyright {
    font-size: var(--font-size-sm);
    color: var(--color-neutral-30);
  }

  @media (min-width: 1024px) {
    .callout {
      grid-template-columns: auto 25%;
      align-items: center;
    }

    .callout-text {
      font-size: var(--font-size-9xl);
      max-width: 22ch;
    }

    .callout-button > a {
      padding: var(--size-4);
      font-size: var(--font-size-lg);
      height: var(--size-9);
      flex: 0;
    }

    .footer {
      grid-template-columns: 0.2fr 0.5fr 0.8fr 1fr;
      margin-block-end: var(--size-10);
    }

    ul + .icon-link-label {
      margin-block-start: var(--size-6);
    }

    .icon-links a {
      height: var(--size-4);
      width: var(--size-4);
    }
  }

  @media (min-width: 1280px) {
    .footer {
      grid-template-columns: 0.3fr 0.5fr 0.8fr 1fr;
      gap: var(--size-10);
    }

    .callout + .footer {
      margin-block-start: var(--size-8);
      padding-block-start: var(--size-8);
    }

    bp-global-footer-email-form {
      order: unset;
      grid-column: unset;
    }
  }

  @media (hover: hover) {
    footer a:hover {
      text-decoration: underline;
    }
  }
`;
